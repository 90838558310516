var nhsInput;
var birthDate;
var phoneNumber;
var referrerPhone;

var metresInput;
var feetInput;
var inchesInput;
var kilosInput;
var stonesInput;
var poundsInput;

function toggleError(main,error) {
	if(main.getAttribute("data-error") != "true") {
		main.setAttribute("data-error", "true");
		main.style.borderColor = "#d0011b";
		main.style.borderBottomRightRadius = "0";
		main.style.borderBottomLeftRadius = "0";
		main.style.borderStyle = "solid";
		error.style.display = "flex";
		error.style.alignItems = "center";
	} else {
		main.setAttribute("data-error", "false");
		error.style.display = "none";
		main.style.borderStyle = "none";
		main.style.borderBottomRightRadius = "5px";
		main.style.borderBottomLeftRadius = "5px";
	}
}

function validateNhsNumber() {
    /**
    * This function takes an NHS number as input and validates its check sum.
    *
    * Parameters:
    * nhsNumber (string): The NHS number to be validated
    *
    * Returns:
    * boolean: True if the check sum is valid, False otherwise
    */
    let nhsNumber = nhsInput.value;
    try {
        
        if(!nhsNumber) {
            throw new Error('NHS Number is required');
        }

        // Remove any whitespace from the input
        nhsNumber = nhsNumber.replace(/\s/g, '');

        // Check if the input has a valid length
        if (nhsNumber.length !== 10) {
            throw new Error('The NHS number must have a length of 10');
        }

        // Extract the check digit from the input
        const checkDigit = parseInt(nhsNumber.charAt(9));

        // Calculate the check sum
        let sum = 0;
        for (let i = 0; i < 9; i++) {
            sum += parseInt(nhsNumber.charAt(i)) * (10 - i);
        }

        // Calculate the expected check digit
        const expectedCheckDigit = (11 - (sum % 11)) % 11;

        // Compare the check digit with the expected check digit
        if (checkDigit === expectedCheckDigit){
            nhsInput.value = nhsNumber.substr(0,3) + " " + nhsNumber.substr(3,3) + " " + nhsNumber.substr(6,4);
            return true;
        } else {
            throw new Error('Invalid NHS Number');
        }
    } catch (error) {
        nhsInput.value = "";
        document.querySelector("#id-d7386c0e-container > div > div > div.error-box > span").innerHTML = error;
        return false;
    }
}

function validateBirthDate() {
    let dob = birthDate.value;
    try {
        //console.log(dob)
        if(!dob) {
            throw new Error('Required');
        }
        
        const regex = new RegExp('^\\d{2}[\\/]\\d{2}[\\/]\\d{4}$')
        
        if (regex.test(dob)){
            //console.log("true");
            return true;
        } else {
            throw new Error('Invalid Date');
        }
    } catch (error) {
        //console.log(error);
        birthDate.value = ""
        document.querySelector("#input-473907b1-container > div > div > div.error-box > span").innerHTML = error;
        return false;
    }
}

function validatePhone(section) {
    let phone = "";
    if (section == "main"){
        phone = phoneNumber.value.replace(/\s/g,'');
    } else if (section == "referrer"){
        phone = referrerPhone.value.replace(/\s/g,'');
    }
    
    try {
        if(!phone) {
            throw new Error('Required');
        }
        
        const regex = new RegExp('^[0]\\d{10}$|^[1-9]\\d{9}$');
        
        if (regex.test(phone)){
            return;
        } else {
            throw new Error('Invalid Phone Number');
        }
    } catch (error) {
        if (section == "main"){
            phoneNumber.value = "";
            document.querySelector("#input-0fc61fe5-container > div > div > div.error-box > span").innerHTML = error;
        } else if (section == "referrer"){
            referrerPhone.value = "";
            document.querySelector("#id-7ff7ff97-container > div > div > div.error-box > span").innerHTML = error;
        }
       
        return;
    }
}

function heightUpdate(changed) {
    let metresError = document.querySelector("#id-b6c3eadd-container > div > div > div.error-box");
    let feetError = document.querySelector("#id-2367995c-container > div > div > div.error-box");
	let inchesError = document.querySelector("#id-08e7fa3c-container > div > div > div.error-box");
	
    if(changed == "metres") {
		metresInput.setAttribute("data-title", metresInput.value);
		let metresValue = +metresInput.getAttribute("data-title");
		try {
			if(metresValue > 3 || !metresValue) {
				throw new TypeError("um");
			}
		} catch (error) {
			metresInput.value = "";
			metresInput.setAttribute("data-title", "");
			feetInput.value = "";
			feetInput.setAttribute("data-title", "");
			inchesInput.value = "";
			inchesInput.setAttribute("data-title", "");
			//document.querySelector("#id-b6c3eadd-container > div > div > div.error-box > span").innerHTML = error;
			
			toggleError(metresInput,metresError);
			return false
		}
        let inches = metresValue * 39.370;
        let feet = Math.floor(inches / 12, 0);
        inches = inches % 12;
		inchesInput.setAttribute("data-title", inches);
		feetInput.setAttribute("data-title", feet);
        inches = inches.toFixed(0);
        
		metresInput.value = metresValue.toFixed(2);
        inchesInput.value = inches;
        feetInput.value = feet;
		
		if(metresInput.getAttribute("data-error") == "true") {
			toggleError(metresInput,metresError);
		}
		if(feetInput.getAttribute("data-error") == "true") {
			toggleError(feetInput,feetError);
		}
		if(inchesInput.getAttribute("data-error") == "true") {
			toggleError(inchesInput,inchesError);
		}
		kilosInput.focus();
    }
    
    if(changed == "feet" || changed == "inches") {
		inchesInput.setAttribute("data-title", inchesInput.value);
		feetInput.setAttribute("data-title", feetInput.value);
		
		let inchesValue = +inchesInput.getAttribute("data-title");
		let feetValue = +feetInput.getAttribute("data-title");
		
		try {
			if(feetValue + inchesValue == 0) {
				throw new TypeError("empty");
			}
			if(feetValue > 9) {
				throw new TypeError("um");
			}
			if(inchesValue > 12) {
				throw new TypeError("um");
			}
		} catch (error) {
			metresInput.value = "";
			metresInput.setAttribute("data-title", "");
			feetInput.value = "";
			feetInput.setAttribute("data-title", "");
			inchesInput.value = "";
			inchesInput.setAttribute("data-title", "");
			
			if(changed == "feet") {
				toggleError(feetInput,feetError);
			} else if (changed == "inches") {
				toggleError(inchesInput,inchesError);
			}
			return false
		}
		
        let metres = ( feetValue * 12 ) + inchesValue;
        metres = metres * 0.0254;
		metresInput.setAttribute("data-title", metres);
        metres = metres.toFixed(2);
        metresInput.value = metres;
		
		feetInput.value = feetValue.toFixed(0);
		inchesInput.value = inchesValue.toFixed(0);
		
		if(metresInput.getAttribute("data-error") == "true") {
			toggleError(metresInput,metresError);
		}
		if(feetInput.getAttribute("data-error") == "true") {
			toggleError(feetInput,feetError);
		}
		if(inchesInput.getAttribute("data-error") == "true") {
			toggleError(inchesInput,inchesError);
		}
		
		if(changed == "inches") {
			kilosInput.focus();
		}
    }
}

function weightUpdate(changed) {
	let kilosError = document.querySelector("#id-aa7a2a08-container > div > div > div.error-box");
    let stonesError = document.querySelector("#id-ea794e4d-container > div > div > div.error-box");
	let poundsError = document.querySelector("#input-d3296c2b-container > div > div > div.error-box");
	
    if (changed == "stones" || changed == "pounds") {
		try {
			if(changed == "stones" && !stonesInput.value) {
			    console.log("stones");
				throw new TypeError("empty");
			}
			if(changed == "pounds" && !poundsInput.value) {
			    console.log("pounds");
				throw new TypeError("empty");
			}
		} catch(error) {
			if(changed == "stones" && stonesInput.getAttribute("data-error") != "true") {
				toggleError(stonesInput,stonesError);
			}
			if(changed == "pounds" && poundsInput.getAttribute("data-error") != "true") {
				toggleError(poundsInput,poundsError);
			}
			return;
		}
		
		stonesInput.setAttribute("data-title", stonesInput.value);
		poundsInput.setAttribute("data-title", poundsInput.value);
		
		let stonesValue = +stonesInput.getAttribute("data-title");
		let poundsValue = +poundsInput.getAttribute("data-title");
		
        let kilos = ( stonesValue * 14 ) + poundsValue;
        kilos = kilos * 0.453592;
		kilosInput.setAttribute("data-title", kilos);
		
		kilosInput.value = kilos.toFixed(0);
		stonesInput.value = stonesValue.toFixed(0);
		poundsInput.value = poundsValue.toFixed(0);
		
		try {
			if(changed == "stones" && !stonesInput.value) {
				throw new TypeError("empty");
			}
			if(changed == "pounds" && !poundsInput.value) {
				throw new TypeError("empty");
			}
		} catch(error) {
			if(changed == "stones" && stonesInput.getAttribute("data-error") != "true") {
				toggleError(stonesInput,stonesError);
			}
			if(changed == "pounds" && poundsInput.getAttribute("data-error") != "true") {
				toggleError(poundsInput,poundsError);
			}
			return;
		}
		
		if(kilosInput.getAttribute("data-error") == "true") {
			toggleError(kilosInput,kilosError);
		}
		if(stonesInput.getAttribute("data-error") == "true") {
			toggleError(stonesInput,stonesError);
		}
		if(poundsInput.getAttribute("data-error") == "true") {
			toggleError(poundsInput,poundsError);
		}
    }
    
    if (changed == "kilos") {
        try {
			if(!kilosInput.value) {
				throw new TypeError("empty");
			}
		} catch(error) {
			if(poundsInput.getAttribute("data-error") != "true") {
				toggleError(kilosInput,kilosError);
			}
			return;
		}
		
		kilosInput.setAttribute("data-title", kilosInput.value);
		let kilosValue = +kilosInput.getAttribute("data-title");
		
        let pounds = kilosValue * 2.2046;
        let stones = Math.floor(pounds / 14, 0);
        pounds = pounds % 14;
		stonesInput.setAttribute("data-title", stones);
		poundsInput.setAttribute("data-title", pounds);
		
		kilosInput.value = kilosValue.toFixed(0);
		stonesInput.value = stones.toFixed(0);
		poundsInput.value = pounds.toFixed(0);
		
		try {
			if(!kilosInput.value) {
				throw new TypeError("empty");
			}
		} catch(error) {
			if(poundsInput.getAttribute("data-error") != "true") {
				toggleError(kilosInput,kilosError);
			}
			return;
		}
		
		if(kilosInput.getAttribute("data-error") == "true") {
			toggleError(kilosInput,kilosError);
		}
		if(stonesInput.getAttribute("data-error") == "true") {
			toggleError(stonesInput,stonesError);
		}
		if(poundsInput.getAttribute("data-error") == "true") {
			toggleError(poundsInput,poundsError);
		}
		document.querySelector("#id-1c998d8f").focus();
    }
}

function cleanSummary() {
    let title = document.getElementById("select-80b94450");
    let address2 = document.getElementById("id-3fd2a40d");
    let email = document.getElementById("id-acb40dc4");
    let mobile = document.getElementById("id-6ccf596c");
    let religion = document.getElementById("input-30c9611e");
    let pLocation = document.getElementById("id-250516e4");
    let cComments = document.getElementById("input-7ef6d932");
    
    /*
    console.log(title.value);
    console.log(address2.value);
    console.log(email.value);
    console.log(mobile.value);
    console.log(religion.value);
    console.log(pLocation.value);
    console.log(cComments.value);
    */
    
    if (!title.value) {
        title.value = " "
    }
    if (!address2.value) {
        address2.value = " ";
    }
    if (!email.value) {
        email.value = " ";
    }
    if (!mobile.value) {
        mobile.value = " ";
    }
    if (!religion.value) {
        religion.value = " ";
    }
    if (!pLocation.value) {
        pLocation.value = " ";
    }
    if (!cComments.value) {
        cComments.value = " ";
    }
    
    let transit = document.getElementById("mco-e04dacaf");
    let selfpropel = document.getElementById("mco-d3712e8e");
    if(transit.checked == true) {
        document.querySelector("#text-block-id-2ab5c441 > div > h3 > span").textContent = "Transit. small wheels, to be pushed by carer";
    } else if (selfpropel.checked == true) {
        document.querySelector("#text-block-id-2ab5c441 > div > h3 > span").textContent = "Self-Propel. big wheels for independence";
    } else {
        document.querySelector("#text-block-id-2ab5c441 > div > h3 > span").textContent = "";
    }
    
    if(pLocation.value == " ") {
        document.querySelector("#text-block-id-c73398c5").style.display = "none"
    } else {
        document.querySelector("#text-block-id-c73398c5").style.display = "block"
    }
    if(cComments.value == " ") {
        document.querySelector("#text-block-id-f99dafd6").style.display = "none"
    } else {
        document.querySelector("#text-block-id-f99dafd6").style.display = "block"
    }

    /*
    if(soresLocation.value == "") {
        document.querySelector("#text-block-id-c73398c5 > div > h3").innerHTML = "";
    } else {
        document.querySelector("#text-block-id-c73398c5 > div > h3").innerHTML = 
        "Location: "
    }
    if(contComments.value == "") {
        document.querySelector("#text-block-id-f99dafd6 > div > h3").innerHTML = "";
    } else {
        document.querySelector("#text-block-id-f99dafd6 > div > h3").innerHTML = 
        "Comments: "
    }
    */
}

window.addEventListener('heyflow-screen-view', (event) => {
    if (event.detail.screenName == "loadinfo" || event.detail.screenName == "submitpage") {
        cleanSummary();
    }
});

window.addEventListener('heyflow-init', (event) => {
	nhsInput = document.getElementById('id-d7386c0e');
	birthDate = document.getElementById('input-473907b1');
	phoneNumber = document.getElementById('input-0fc61fe5');
	referrerPhone = document.getElementById('id-7ff7ff97');
	
	metresInput = document.getElementById("id-b6c3eadd");
	feetInput = document.getElementById("id-2367995c");
	inchesInput = document.getElementById("id-08e7fa3c");
	kilosInput = document.getElementById("id-aa7a2a08");
	stonesInput = document.getElementById("id-ea794e4d");
	poundsInput = document.getElementById("input-d3296c2b");
	
	metresInput.setAttribute("data-title","");
	feetInput.setAttribute("data-title","");
	inchesInput.setAttribute("data-title","");
	
    nhsInput.addEventListener("change", validateNhsNumber);
    birthDate.addEventListener("change", validateBirthDate);
    phoneNumber.addEventListener("change", function() {validatePhone("main")});
    referrerPhone.addEventListener("change", function() {validatePhone("referrer")});
    
    metresInput.addEventListener("focusout", function() {heightUpdate("metres")});
    feetInput.addEventListener("focusout", function() {heightUpdate("feet")});
    inchesInput.addEventListener("focusout", function() {heightUpdate("inches")});
	kilosInput.addEventListener("focusout", function() {weightUpdate("kilos")});
    stonesInput.addEventListener("focusout", function() {weightUpdate("stones")});
    poundsInput.addEventListener("focusout", function() {weightUpdate("pounds")});
    
    document.querySelector("#textarea-f4b2ef96").setAttribute("maxlength","5000");
    document.querySelector("#textarea-ea8da827").setAttribute("maxlength","500");
    document.querySelector("#id-250516e4").setAttribute("maxlength","500");
    document.querySelector("#input-7ef6d932").setAttribute("maxlength","500");
    document.querySelector("#id-96d1cf20").setAttribute("maxlength","500");
    document.querySelector("#input-30c9611e").setAttribute("maxlength","500");
    document.querySelector("#id-7a1e87b6").setAttribute("maxlength","500");
});